import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FullAddressControlComponent } from './full-address-control.component';


@NgModule({
  declarations: [
    FullAddressControlComponent,
  ],
  exports: [
    FullAddressControlComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    FlexLayoutModule,
    MatIconModule,
  ],
})
export class FullAddressControlModule {
}
