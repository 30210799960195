import { animate, style, transition, trigger } from '@angular/animations';
import { FocusMonitor } from '@angular/cdk/a11y';

import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FullAddressControlComponent } from '@bc-common/widgets/full-address-control';
import { ValidationMessageService } from '@bc-core/services';
import { FormFieldAbstractControl } from '@shared/form-controls/form-field/form-field-abstract-control';
import { InputDirective } from '@shared/form-controls/input/input.directive';
import { FieldLabelComponent } from '@shared/ui-components/field-label/field-label.component';
import { startCase } from 'lodash-es';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'us2-form-field',
  standalone: true,
  imports: [
    MatIconModule,
    FieldLabelComponent
],
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('errorInOut', [
      transition(':enter', [
        style({ height: 0 }),
        animate('100ms', style({ height: '*' })),
      ]),
      transition(':leave', animate('100ms', style({ opacity: '0', height: '0' })))
    ]),
  ]
})
export class FormFieldComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() label: string;
  @Input() description?: string;
  @Input() appearance: 'outline' | 'underline' = 'underline';
  @ContentChild(InputDirective) input: InputDirective;
  @ContentChild(FullAddressControlComponent) addressInput: FullAddressControlComponent;
  @HostBinding('class') class = `us2-form-field`;
  @HostBinding('class.focused') focused: boolean;
  control: NgControl;
  isRequired: boolean;
  isPassword: boolean;

  // need to think about it, using last control as main control of the field
  @ContentChildren(NgControl) set controls(v: QueryList<NgControl>) {
    this.control = v.last;
    this.isRequired = this.control?.control.hasValidator(Validators.required);

    if (this.input?.placeholder && this.isRequired) {
      this.input.placeholder = `${this.input.placeholder}*`;
    }
  }

  @HostBinding('class.invalid') get invalid() {
    return this.control?.invalid;
  }

  @HostBinding('class.touched') get touched() {
    return this.control?.touched;
  }

  @HostBinding('class.address-input') get isAddressInput(): boolean {
    return !!this.addressInput;
  }

  @Input({ transform: booleanAttribute })
  @HostBinding('class.disabled') get disabled() {
    return this.control?.disabled || this.input?.disabled;
  }

  get message(): string | void {
    if (!this.control?.errors) {
      return;
    }

    const friendlyControlName = startCase(this.control.name).toLowerCase();
    const key = Object.keys(this.control.errors)[ 0 ];
    const error = this.control.getError(key);

    return this.messageService.getMessage(key, friendlyControlName, error);
  }

  get inputType(): string | undefined {
    return this.input?.type;
  }

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: ValidationMessageService,
    private fm: FocusMonitor,
    private element: ElementRef,
  ) {
    this.fm.monitor(this.element, true)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(origin => {
        this.focused = !!origin && this.control?.enabled;
        this.cdr.markForCheck();
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterContentInit(): void {
    let changes$ = [];

    if (this.control) {
      changes$ = [ ...changes$, this.control.valueChanges, this.control.statusChanges ];

      if (this.control.valueAccessor instanceof FormFieldAbstractControl) {
        changes$ = [ ...changes$, this.control.valueAccessor.stateChanged$ ];
      }
    }

    if (this.input) {
      this.isPassword = this.input.type === 'password';
      changes$ = [ ...changes$, this.input.stateChanged$ ];
    }

    merge(...changes$)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(() => this.cdr.markForCheck());
  }

  toggleVisibility(): void {
    this.input?.toggleVisibility();
  }
}
