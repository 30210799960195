import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[us2Input]',
  standalone: true
})
export class InputDirective {
  @HostBinding('class') class = 'us2-input-element';

  @Input()
  @HostBinding('attr.placeholder') placeholder : string;

  stateChanged$ = new Subject<void>();

  get htmlEl(): HTMLInputElement {
    return this.el.nativeElement;
  }

  get type(): string {
    return this.htmlEl.type;
  }

  get disabled(): boolean {
    return this.htmlEl.disabled;
  }

  constructor(
    private el: ElementRef<HTMLInputElement>,
  ) { }

  @HostListener('blur')
  onInput(): void {
    this.stateChanged$.next();
  }

  toggleVisibility(): void {
    this.htmlEl.type = this.type === 'password' ? 'text' : 'password';
  }
}
