import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'us2-field-label',
  standalone: true,
  imports: [],
  templateUrl: './field-label.component.html',
  styleUrl: './field-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldLabelComponent {
  @Input() label: string;
  @Input() description?: string;
  @Input() isRequired?: boolean;
}
