@if (label) {
  <div class="label mb-1">

    <span>{{ label }}</span>

    @if (isRequired) {
      <sup>*</sup>
    }

  </div>
}

@if (description) {

  <div class="description">{{ description }}</div>

}

<div class="control-container flex" [class]="'appearance-' + appearance">
  <ng-content></ng-content>

  @if (isPassword) {
    <div class="visibility-btn" (click)="toggleVisibility()">
      <mat-icon>{{ inputType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
    </div>
  }
</div>

@if (invalid && touched) {

  <div class="error-message mt-1.5" [@errorInOut]>
    <div class="error-message-inner">{{ message }}</div>
  </div>

}
