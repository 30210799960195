<div [class.mb-1]="!description" class="label">

  <span>{{ label }}</span>

  @if (isRequired) {
    <sup>*</sup>
  }

</div>

@if (!!description) {

  <div class="description mb-1">{{ description }}</div>

}
