import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf
} from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { Us2ThemePalette } from '@shared/models/theme-palette';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'us2-submit-button',
  standalone: true,
  imports: [
    ButtonComponent
  ],
  templateUrl: './submit-button.component.html',
  styleUrl: './submit-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() color: Us2ThemePalette = 'primary';
  @Input() isLoading = false;
  @Input() checkPristine = true;
  @Input() disabled: boolean;
  form: UntypedFormGroup;
  isDisabled = true;

  private readonly destroyed$ = new Subject<null>();

  constructor(
    @Optional() @SkipSelf() private formDirective: FormGroupDirective,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.form = this.formDirective?.form;

    this.form?.statusChanges
      .pipe(
        startWith(''),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => this.updateDisabledState());
  }

  ngOnChanges(): void {
    this.updateDisabledState();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private updateDisabledState(): void {
    const isPristine = this.checkPristine && this.form?.pristine;

    this.isDisabled = this.disabled || isPristine || this.form?.invalid;

    this.cdr.markForCheck();
  }
}

