<input class="address-input" (keyup)="onInputKeyUp($event)" (blur)="onInputFocus()" [matAutocomplete]="auto"
       [disabled]="disabled" [value]="formattedAddress$ | async" type="text" matInput [placeholder]="placeholder">

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" class="shadow-md bg-white"
                  [displayWith]="displayPrediction">

  <ng-container *ngIf="predictions.length; else noPredictions">
    <mat-option *ngFor="let prediction of predictions" [value]="prediction"
                class="address-option address-option-prediction">

      <span class="title">{{ prediction.structured_formatting.main_text }}</span>
      <br/>
      <small class="description">{{ prediction.description }}</small>
    </mat-option>
  </ng-container>

  <ng-template #noPredictions>
    <mat-option>
      <ng-container i18n>No results found</ng-container>
    </mat-option>
  </ng-template>

  <div class="powered-by-google-container">
    <div #poweredByGoogle class="powered-by-google"></div>
  </div>

  <mat-option *ngIf="showDetailsOption && formattedAddress" class="address-option address-option-details" [class]="primaryBackgroundClass">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="18px">
      <mat-icon class="pin-icon" svgIcon="location-circle" [class]="primaryTextClass"></mat-icon>

      <div class="details-text" fxFlex>
        <ng-container i18n><strong class="title">Can’t find it in this list?</strong> <br/><small class="description">Click
          here to type in the address and details.</small></ng-container>
      </div>

      <mat-icon class="arrow-icon">chevron_right</mat-icon>
    </div>
  </mat-option>
</mat-autocomplete>
