import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Address, AddressFormService, BOX_APP_CONFIG } from '@bc-libs/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import PlaceResult = google.maps.places.PlaceResult;
import PlacesService = google.maps.places.PlacesService;
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus;

@Component({
  selector: 'bcc-full-address-control',
  templateUrl: './full-address-control.component.html',
  styleUrls: [ './full-address-control.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: FullAddressControlComponent,
    },
  ],
})
export class FullAddressControlComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<string> {
  static nextId = 0;

  @ViewChild('poweredByGoogle', { static: true }) poweredByGoogle: ElementRef;

  @HostBinding('[class.floating]') get floating(): boolean { return this.shouldLabelFloat; }

  @HostBinding('id') get id(): string { return `full-address-input-${FullAddressControlComponent.nextId++}`; }

  @HostBinding('[attr.aria-describedby]') get describedBy(): string { return this._describedBy; }

  @Input() showDetailsOption = true;
  @Output() detailsSelected = new EventEmitter<void>();
  @Output() addressChanged = new EventEmitter<Address>();

  autocompleteService: google.maps.places.AutocompleteService;
  placesService: PlacesService;
  predictions: AutocompletePrediction[] = [];
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'full-address-input';
  onChange: (val: any) => {};
  onTouched: () => {};
  primaryBackgroundClass: string;
  primaryTextClass: string;

  private formattedAddressSubject = new BehaviorSubject<string>('');
  private readonly destroyed$ = new Subject<void>();
  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  private _describedBy: string;

  #appConfig = inject(BOX_APP_CONFIG);

  formattedAddress$: Observable<string> = this.formattedAddressSubject.asObservable();

  constructor(
    private addressFormService: AddressFormService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private fm: FocusMonitor,
    private ngZone: NgZone,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.primaryBackgroundClass = this.#appConfig?.paletteClasses.primary?.background;
    this.primaryTextClass = this.#appConfig?.paletteClasses?.primary?.text;

    if (!this.autocompleteService) {
      this.initAutocomplete();
    }

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    this.fm.monitor(elementRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  @Input()
  get placeholder(): string { return this._placeholder; }

  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean { return this._required; }

  set required(required) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  @Input()
  set disabled(disabled) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }

  get disabled(): boolean { return this._disabled; }

  @Input()
  get value(): string | null {
    return this.formattedAddress;
  }

  set value(formattedAddress: string | null) {
    this.formattedAddress = formattedAddress;

    if (this.onChange) {
      this.onChange(formattedAddress);
    }

    this.stateChanges.next();
  }

  get empty(): boolean {
    return !this.value;
  }

  get errorState(): boolean {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  get shouldLabelFloat(): boolean { return this.focused || !this.empty; }

  get formattedAddress(): string {
    return this.formattedAddressSubject.getValue();
  }

  set formattedAddress(formattedAddress: string) {
    this.formattedAddressSubject.next(formattedAddress);
  }

  ngOnInit(): void {
    this.initServices();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elementRef.nativeElement);
  }

  displayPrediction(prediction?: AutocompletePrediction): string {
    return prediction?.description;
  }

  setDescribedByIds(ids: string[]): void {
    this._describedBy = ids.join(' ');
  }

  onInputKeyUp(event: KeyboardEvent): void {
    this.formattedAddress = (event.target as any).value;
  }

  onInputFocus(): void {
    if (this.onTouched) {
      this.onTouched();
    }

    this.stateChanges.next();
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elementRef.nativeElement.querySelector('input').focus();
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const { value } = event.option;

    if (!value) {
      this.detailsSelected.emit();
      return;
    }

    this.value = value.description;
    this.cdr.markForCheck();

    const { place_id: placeId } = value;

    this.placesService.getDetails({ placeId }, (place: PlaceResult, status: PlacesServiceStatus) => {
      // @todo using string instead of PlacesServiceStatus.OK, because 'google' is undefined error, need to find the way to fix it
      this.ngZone.run(() => {
        if (status !== 'OK') {
          return;
        }

        const address = this.addressFormService.getAddressFromGooglePlace(place);

        if (!address) {
          return;
        }

        this.addressChanged.emit(address);
      });
    });
  }

  registerOnChange(fn: any): void { this.onChange = fn; }

  registerOnTouched(fn: any): void { this.onTouched = fn; }

  writeValue(value: string): void {
    this.formattedAddress = value;
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private initAutocomplete(): void {
    this.formattedAddress$
      .pipe(
        debounceTime(500),
        filter(input => !!input),
        switchMap(input => fromPromise(this.autocompleteService.getPlacePredictions({ input }))),
        takeUntil(this.destroyed$),
      )
      .subscribe(resp => {
        this.predictions = resp.predictions;
        this.cdr.markForCheck();
      });
  }

  private initServices(): void {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(this.poweredByGoogle.nativeElement);
  }
}
